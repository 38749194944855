$icomoon-woff-font-path: "./assets/fonts/icomoon.woff";
$font-family-brand: 'icomoon';

@font-face {
	font-family: 'icomoon';
	src: url("./assets/fonts/icomoon.eot?gp03mb"),
	url("./assets/fonts/icomoon.eot?gp03mb#iefix") format("embedded-opentype"),
	url("./assets/fonts/icomoon.ttf?gp03mb") format("truetype"),
	url("./assets/fonts/icomoon.woff?gp03mb") format("woff"),
	url("./assets/fonts/icomoon.svg?gp03mb#icomoon") format("svg");
	font-weight: normal;
	font-style: normal;
}
