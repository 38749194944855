:root {
	--bg-primary: white;
	--color-primary: black;
	--bs-primary-h: 220;
	--bs-primary-s: 43%;
	--bs-primary-l: 50%;
	--bs-primary: hsl(var(--bs-primary-h) var(--bs-primary-s) var(--bs-primary-l));
	--bs-bold-color: hsl(var(--bs-primary-h) var(--bs-primary-s) max(calc(var(--bs-primary-l) - 15%),0%));
	--bs-hover-color: hsl(var(--bs-primary-h) var(--bs-primary-s) min(calc(var(--bs-primary-l) + 15%),90%));
	--bs-text-color: #ffffff;

	--bg-secondary: #f8f9fa;
	--light-gray: #D3D3D3;
	--light-blue: #f2faff;
	--light-blue2: #f7f6f9;
	--light-gray-at: #e9e9e9ad; //with alpha transparency
	--gray: #808080;
	--gray2: #696969;
	--gray-dark: #282828;

	--primary-color: hsl(var(--primary-color-h), var(--primary-color-s), var(--primary-color-l));
	--primary-color-h: 220;
	--primary-color-s: 43%;
	--primary-color-l: 50%;
	--primary-color-dark: hsl(var(--primary-color-h),var(--primary-color-s), calc(var(--primary-color-l) - 15%));

	--blue: blue;
	--danger: #dd0d13;
	--warning: darkorange;
	--success: darkgreen;
}

[data-theme="dark"] {
	--bg-primary: rgb(34, 36, 37);
	--bg-secondary: rgb(41, 43, 44);
	--color-primary: white;
	--light-gray: #585858;
	--light-blue: #303233;
	--light-blue2: #292929;
	--light-gray-at: rgba(67, 67, 67, 0.68); //with alpha transparency
	--gray: #A9A9A9;
	--gray2: #BEBEBE;
	--gray-dark: #D7D7D7;
	--primary-color-dark: hsl(var(--primary-color-h), var(--primary-color-s), var(--primary-color-l));;
	--blue: #0093ff;
	--danger: #ff0000;
	--warning: #ffc107;
	--success: #28a745;

	.app-container {
		&:before {
			background: linear-gradient(rgba(17, 17, 24, 0.75), rgba(17, 17, 24, 0.6) , rgba(17, 17, 24, 0.75))!important;
		}
	}

	.auth-main-container {
		background: radial-gradient(#555, #333, rgba(51,51,51,0.95)),
		repeating-linear-gradient(45deg, #1a365f 0%, rgba(18, 34, 66, 0.6) 5%, transparent 5%, transparent 10%),
		repeating-linear-gradient(-45deg, rgba(20, 45, 82, 0.4) 0%, rgba(17, 34, 66, 0.5) 5%, transparent 5%, transparent 10%)!important;
	}

	.threat-protection-card {
		.card-header { background: linear-gradient(90deg, #2d2d2f, var(--bg-primary))!important;}
	}

	.fancy-background {
		&:before {
		background: repeating-linear-gradient(-45deg, transparent, transparent 1em, rgba(35, 35, 35, 0.4) 0, hsla(0deg, 0%, 27%, 10%) 2em, transparent 0, transparent 1em, hsla(0deg, 0%, 27%, 30%) 0, hsla(0deg, 0%, 27%, 20%) 4em, transparent 0, transparent 1em, hsla(0deg, 0%, 13%, 60%) 0, hsla(0deg, 0%, 13%, 20%) 2em), repeating-linear-gradient(45deg, transparent, transparent 1em, hsla(0deg, 0%, 27%, 40%) 0, rgba(63, 63, 63, 0.1) 4em)!important;
		}

		&:after {
			background: linear-gradient(90deg, #1a1a1a, rgba(26, 26, 26, 0.25) 15%, rgba(26, 26, 26, 0.48) 75%, #1a1a1a),
			linear-gradient(0deg, #1a1a1a, transparent),
			linear-gradient(0deg, rgba(26, 26, 26, 0.5), rgba(26, 26, 26, 0.5))!important;

		}
	}

	.dropdown {
		.select {
			color: var(--color-primary);
		}
		&:hover {
			background: unset!important;
		}
		&.active {
			background: unset!important;
			background-color: unset!important;

			&:hover {
				background: unset!important;
			}
		}
	}
	input {
		color: inherit;
		background-color: var(--bg-primary);
	}
	input:disabled {
		background-color: var(--bg-secondary)
	}

	.hvr-darken {
		&1:hover { filter: brightness(0.65); }
		&2:hover { filter: brightness(0.75); }
		&3:hover { filter: brightness(0.85); }
	}

	.t-dark-mode-filter {
		filter: brightness(0.67) hue-rotate(-200deg) invert(0.85) contrast(2.5) saturate(0.65);
		overflow: hidden; // pay attention: filter causes the position:fixed elements to be fixed to it!
		will-change: filter; // Safari bug fix
	}

	.brightnessDark {
		filter: brightness(1.5);
	}

	.listTable-c {
		table {
			background-color: var(--bg-primary);

			tbody tr {
				&:hover {
					background-color: lighten(#353839FF, 5%);
				}
			}

			thead {
				th {
					background-color: var(--bg-secondary);
				}
			}
		}

		&.alternated {
			table {
				tbody tr:nth-child(odd) {
					background-color: var(--bg-secondary);
				}

				tbody:nth-child(even) {
					background-color: var(--bg-primary);
				}

				tr:nth-child(odd) {
					background-color: var(--bg-secondary);
				}

				tr:hover {
					background-color: lighten(#292B2CFF, 5%)!important;
				}
			}
		}
	}

}

