@import 'assets/styles/roots';


html {
	box-sizing: border-box;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

body {
	color: #000;
	background: #fff;
	font: 13px/1.2 "Roboto", "Helvetica Neue", "Helvetica", sans-serif;
	font-size: 16px;
	min-width: 320px;
	-webkit-font-smoothing: antialiased;
	margin: 0;
	overflow-x: hidden;
}

h1,
.h1 {
	font-size: 23px;
	font-weight: 300;
	font-family: "Roboto", "Helvetica Neue", "Helvetica", sans-serif;
}
a:active,
a:hover {
	outline: 0 !important;
}
input[type="text"],
input[type="tel"],
input[type="number"],
input[type="email"],
input[type="search"],
input[type="password"],
textarea {
	border-radius: 4px !important;
	box-sizing: border-box !important;
	border: 1px solid #ccc;
	padding: 2px 8px;
}
input[type="text"]:focus,
input[type="tel"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="search"]:focus,
input[type="password"]:focus,
textarea:focus {
	transition: all .3s ease-in-out;
	outline: none !important;
}
input[type="text"]::-webkit-input-placeholder,
input[type="tel"]::-webkit-input-placeholder,
input[type="number"]::-webkit-input-placeholder,
input[type="email"]::-webkit-input-placeholder,
input[type="search"]::-webkit-input-placeholder,
input[type="password"]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
	color: #6a6a6a !important;
}
input[type="text"]::-moz-placeholder,
input[type="tel"]::-moz-placeholder,
input[type="number"]::-moz-placeholder,
input[type="email"]::-moz-placeholder,
input[type="search"]::-moz-placeholder,
input[type="password"]::-moz-placeholder,
textarea::-moz-placeholder {
	opacity: 1 !important;
	color: #6a6a6a !important;
}
input[type="text"]:-moz-placeholder,
input[type="tel"]:-moz-placeholder,
input[type="email"]:-moz-placeholder,
input[type="search"]:-moz-placeholder,
input[type="password"]:-moz-placeholder,
textarea:-moz-placeholder {
	color: #6a6a6a !important;
}
input[type="text"]:-ms-input-placeholder,
input[type="tel"]:-ms-input-placeholder,
input[type="number"]:-ms-input-placeholder,
input[type="email"]:-ms-input-placeholder,
input[type="search"]:-ms-input-placeholder,
input[type="password"]:-ms-input-placeholder,
textarea:-ms-input-placeholder {
	color: #6a6a6a !important;
}
input[type="text"].placeholder,
input[type="tel"].placeholder,
input[type="number"].placeholder,
input[type="email"].placeholder,
input[type="search"].placeholder,
input[type="password"].placeholder,
textarea.placeholder {
	color: #6a6a6a !important;
}

:-ms-input-placeholder {
	color: #6a6a6a;
}

::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}
::-webkit-scrollbar-button {
	width: 0px;
	height: 0px;
}
::-webkit-scrollbar-thumb {
	border: 1px solid rgba(0, 0, 0, 0.5);
	border-radius: 50px;
}
::-webkit-scrollbar-track {
	background: #ffffff;
	border: 1px solid rgba(0,0,0,0.15);
	border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
	background: #ffffff;
}
::-webkit-scrollbar-track:active {
	background: #ffffff;
}
::-webkit-scrollbar-corner {
	background: transparent;
}

::-ms-clear {
	display: none;
}

.zdmi-icons {
	float: right;
	cursor: pointer;
	padding-left: 15px;
	padding-right: 5px;
	margin-top: -7px;
	color: #486db5;
}

a {
	cursor: pointer;
	background-color: transparent;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	margin: 0;
}

input[type="number"] {
	-moz-appearance: textfield;
}

@media print {
	.form.validation-form, .authenticated-section {
		display: none !important;
	}

	.full-screen-content {
		height: auto !important;
		overflow: visible;
	}

	.html-content {
		height: auto !important;
		overflow: visible;
	}

	body {
		word-break: break-all;
	}
}

@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@-moz-keyframes spin {
	0% {
		-moz-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-moz-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@-ms-keyframes spin {
	0% {
		-ms-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-ms-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes dotty {
	0%, 20% {
		color: rgba(0,0,0,0);
		text-shadow:
				.25em 0 0 rgba(0,0,0,0),
				.5em 0 0 rgba(0,0,0,0);
	}
	40% {
		color: gray;
		text-shadow:
				.25em 0 0 rgba(0,0,0,0),
				.5em 0 0 rgba(0,0,0,0);
	}
	60% {
		text-shadow:
				.25em 0 0 gray,
				.5em 0 0 rgba(0,0,0,0);
	}
	80%, 100% {
		text-shadow:
				.25em 0 0 gray,
				.5em 0 0 gray;
	}
}

.btn.dropbtn {
	padding: 7px;
	min-width: 0;
	outline: 0;
}

.btn.dropbtn:hover {
	box-shadow: none;
}

.loading-dots.blocked {
	font-size: 20px;
	position: absolute;
	top: 20px;
	left: 110px;
}
@media (min-width: 768px) {
	.loading-dots.blocked {
		left: 130px;
	}
}

.loading-dots:after {
	 display: inline-block;
	 content: ' .';
	 animation: dotty 1s steps(5, end) infinite;
}

input.form-control {
	height: 30px;
	width: 40px;
	box-sizing: border-box;
	border: 0;
	padding-left: 2px;
	outline: 0;
}

.action-details international-phone-number .input-group-align, .action-details .form-control {
	height: 32px;
	width: 40px;
	box-sizing: border-box;
	outline: 0;
	border-radius: 4px !important;
	border: 1px solid #ccc;
	padding: 2px 1px 2px 0;
}

.action-details international-phone-number .input-group-align, .action-details .form-control:focus {
	border-color: #ccc;
}

.action-details international-phone-number  {
	// avoid L&F color apply for this button
	button {
		background: white !important;
		&:focus {
			background: white !important;
		}
		&:hover {
			background: #f1f1f1 !important;
		}
	}
	.dropdown {
		box-shadow: none;
		&:hover {
			box-shadow: none;
		}
	}
}

.action-details international-phone-number.disabled input, .action-details international-phone-number.disabled button {
	background: rgb(235, 235, 228) !important;
}

.action-details international-phone-number.disabled input {
	opacity: 0.5; // equalize opacity with nearby dropdown which gets 0.5 from other rule when disabled (.dropdown)
}

.action-details international-phone-number.disabled .btn:focus, .action-details international-phone-number.disabled .btn:hover {
	background: rgb(235, 235, 228);
}

.action-details international-phone-number .btn {
	transition: none;
}

.action-details input.input-group-align {
	height: 32px;
	width: 120px;
	box-sizing: border-box;
	outline: 0;
	border-radius: 4px !important;
	border: 1px solid #ccc;
	padding: 2px 8px;
}

.action-details .form-control {
	border-left: 0 !important;
	border-top-left-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
}

.arrow-down {
	margin-top: 4px !important;
	margin-left: 4px !important;
}

.list-group-item:hover {
	background-color: rgba(204, 204, 204, 0.03);
}

[class^="icon-"],
[class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: "icomoon" !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1; /* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.confirm-text .input-group {
	display: flex;
	align-items: center;
}

.country-code .input-group .dropdown .dropbtn {
	outline: none !important;
	height: 32px;
	width: 45px;
	box-sizing: border-box;
	outline: 0;
	border-radius: 4px !important;
	border: 1px solid #ccc;
	padding: 2px 0;
	border-right: 0 !important;
	border-top-right-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
}

.country-code .input-group .list-group-item {
	padding: 6px 6px !important;
}

.country-code .input-group .list-group-item:hover {
	background: rgba(204, 204, 204, 0.3) !important;
}

.country-code .list-group.scrollable-menu {
	width: 205px;
	max-height: 80px;
}
.country-code.higher .list-group.scrollable-menu {
	max-height: 22vh;
}

.country-code .list-group.scrollable-menu .list-group-item .country-name .dial-code {
	float: right;
	padding-right: 5px;
}

//Temp fix from: https://github.com/nikhiln/ngx-international-phone-number/issues/77.
.input-group-addon .dropdown {
	.dropbtn .flag {
		background-image: url('assets/images/countryFlags/country-flags.jpg') !important;
	}
	.dropdown-content .list-group .list-group-item .flag {
		background-image: url('assets/images/countryFlags/country-flags.jpg') !important;
	}
}

.errorTooltip {
	color: #C2272E !important;
}

.tooltip {
	word-break: break-word;
}

input:required {
	box-shadow: none !important;
}

.ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding-right: 5px;
}

[data-hidden="true"] {
	display: none !important;
}

international-phone-number > div {
	 display: inline;
}

international-phone-number > div button.btn.dropbtn {
	width: inherit;
	height: inherit;
	min-height: inherit;
	border-radius: inherit;
	margin: inherit;
}

.disabled {
	label, .dropdown {
		opacity: 0.5 !important;
		pointer-events: none;
		cursor: not-allowed;
	}
	&.holder {
		>input {
			opacity: 0.5 !important;
			pointer-events: none;
			cursor: not-allowed;
		}
	}
}

.dropdown {
	width: 70%;
	z-index: 5;
	display: inline-block;
	background-color: var(--bg-primary);
	border-radius: 5px;
	box-shadow: 0 0 2px #cccccc;
	position: relative;
	font-size: inherit;
	color: #474747;
	height: 22px;
	text-align: left;
	top: -2px;

	&:hover {
		box-shadow: 0 0 5px rgb(204, 204, 204);
	}
	&.active {
		background-color: #f8f8f8;
		.select > i {
			transform: rotate(-90deg);
		}
	}
	&:hover, &.active {
		box-shadow: 0 0 5px rgb(204, 204, 204);
		border-radius: 5px 5px 0 0;
		background-color: #f8f8f8;
	}

	.select {
		padding:3px;
		cursor: pointer;
		display: flex;
		color: black;
		i {
			font-size: 12px;
			color: var(--gray);
			cursor: pointer;
			transition: all .3s ease-in-out;
			float: right;
			padding-top: 1px;
			position: absolute;
			right: 5px;
			top: 5px;
		}
	}
	.dropdown-menu {
		position: absolute;
		padding: 0;
		list-style: none;
		color: var(--color-primary);
		background-color: var(--bg-primary);
		width: 100%;
		left: 0;
		top: 8px;
		box-shadow: 0 1px 2px #cccccc;
		border-radius: 0 1px 5px 5px;
		overflow-x: hidden;
		overflow-y: auto;
		max-height: 0;

		li {
			padding: 10px;
			transition: all .2s ease-in-out;
			cursor: pointer;
			max-height: unset;
			min-height: unset;
			&:hover {
				background: rgba(204, 204, 204, 0.3);
			}
			label {
				position: relative;
				cursor: pointer;
				margin-bottom: 0;
				height: 100%;
				width: 100%;
				display: block;
				line-height: 34px;
				input[type="checkbox"] {
					display: none;
					&:checked {
						~ {
							.label-value {
								&:before {
									content: '\f058';
									font-weight: 900;
								}
							}
						}
					}
				}
				.label-value {
					position: absolute;
					display: flex;
					font-size: 14px;
					max-width: 100%;
					height: 100%;
					width: 100%;
					&:before {
						font-family: "Font Awesome 5 Free";
						font-style: normal;
						width: 1em;
						height: 1em;
						content: '\f111';
						margin-right: .3em;
						font-weight: 400;
						font-size: 14px;
					}
				}
				&.disabled {
					opacity: 0.5;
					pointer-events: none;
				}
			}
		}
	}
}

.recipients-dropdown-wrapper {
	margin-bottom: 20px;
	@media (max-width: 600px) {
		margin-bottom: 40px;
	}
	.heading {
		border-bottom: 0;
		height: 53px;
		position: relative;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		padding: 19px 0 12px;
		@media (max-width: 600px) {
			flex-direction: column;
		}
		i {
			font-size: 21px;
		}
		.search-and-refresh {
			margin: 5px auto 0 15px;
			@media (max-width: 600px) {
				margin: 10px auto 0 15px;
			}
		}
	}

	.label {
		margin: 5px 0 0 15px;
		color: #818181;
		@media (max-width: 600px) {
			margin: 5px 0 0 5px;
			padding-top: 8px;
		}
	}
}

.no-recipient-found {
	font-size: 17px;
	font-style: italic;
	color: #818181;
}

@mixin justification($class) {
	&-center {
		@extend #{$class};
		justify-content: center !important;
	}
	&-start {
		@extend #{$class};
		justify-content: flex-start !important;
	}
	&-end {
		@extend #{$class};
		justify-content: flex-end !important;
	}
	&-between {
		@extend #{$class};
		justify-content: space-between !important;
	}
	&-around {
		@extend #{$class};
		justify-content: space-around !important;
	}
	&-evenly {
		@extend #{$class};
		justify-content: space-evenly !important;
	}
}

.flxRow {
	display: flex !important;
	flex-direction: row;
	@include justification(&);
}

.flxClmn {
	display: flex !important;
	flex-direction: column;
	@include justification(&);
}
.overflow-auto {
	overflow: auto;
}


// spacing between vertical aligned items (apply this to parent)
// example: vSpace1, .vSpace2
.vSpace {
	@for $i from 1 through 5 {
		&#{$i} {
			> * {
				margin: calc(5px * #{$i}) 0;

				&:first-child {
					margin-top: 0;
				}

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
	&05 {
		> * {
			margin: 2.5px 0;

			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

// spacing between horizontal aligned items (apply this to parent)
// example: .hSpace1, .hSpace2
.hSpace {
	@for $i from 1 through 5 {
		&#{$i} {
			> * {
				margin: 0 calc(5px * #{$i});

				&:first-child {
					margin-left: 0;
				}

				&:last-child {
					margin-right: 0;
				}
			}
		}
	}
	&05 {
		> * {
			margin: 0 2.5px;

			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}
}

.h100 {
	height:100%;
}

.w100 {
	width:100%;
}

.simpleDataTable{
	$bgClr: var(--bg-primary);
	$secondaryClr: var(--bg-secondary);

	td, th{
		padding: 10px;
		border-bottom: solid 1px rgba(0,0,0,0.05);
		text-align: left;
	}
	td:first-child, th:first-child{
		padding-left: 0;
	}

	&Shrink{
		td, th{
			padding: 5px;
			border-bottom: none;
			text-align: left;
		}
		&:not(.middle) {
			td, th{
				vertical-align: top;
			}
		}
	}
	&.sticky-header {
		th {
			position: sticky;
			top: 0;
			background: $bgClr;
			z-index: 15;
		}
	}
	&.ellips{
		span {
			@extend .ellipsis;
			padding-right: unset;
		}
		td > span:only-child {
			display: block;
			width: fit-content;
			max-width: 100%;
		}
		th:not(.overflownV),
		td:not(.overflownV) {
			overflow: hidden;
			text-overflow: ellipsis;
			div {
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
	&.alternated-grey {
		tbody tr:nth-child(odd) {
			background: var(--bg-secondary);
		}
	}
}

.displayFlowAdvanced .switch-toggle {
	font-size: 28px !important;
}

.vertical-separator {
	height: 100%;
	width: 0;
	border: solid 1px;
}

// material-design-like chackbox

$md-checkbox-checked-color: #195ee2;
$md-checkbox-border-color: rgba(0, 0, 0, 0.54);
$md-checkbox-border-color-disabled: rgba(0, 0, 0, 0.26);
$md-checkbox-checked-color-disabled: rgba(0, 0, 0, 0.26);

$md-checkbox-margin: 0;
$md-checkbox-size: 1.25em;
$md-checkbox-padding: .25em;
$md-checkbox-border-width: 2px;
$md-checkbox-border-radius: 0.125em;
$md-checkmark-width: 0.2em;
$md-checkmark-color: #fff;
$md-checkbox-label-padding: 1.75em;

.md-checkbox {
	position: relative;
	margin: $md-checkbox-margin;
	text-align: left;

	&.md-checkbox-inline {
		display: inline-block;
	}

	label {
		cursor: pointer;
		display: inline;
		line-height: $md-checkbox-size;
		vertical-align: top;
		clear: both;
		padding-left: 1px;
		&:not(:empty) {
			padding-left: $md-checkbox-label-padding;
		}

		&:before, &:after {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
		}

		&:before {
			// box
			width: $md-checkbox-size;
			height: $md-checkbox-size;
			background: #fff;
			border: $md-checkbox-border-width solid $md-checkbox-border-color;
			border-radius: $md-checkbox-border-radius;
			cursor: pointer;
			transition: background .3s;
		}

		&:after {
			// checkmark
		}
	}

	input[type="checkbox"] {
		outline: 0;
		visibility: hidden;
		width: 0;
		margin: 0;
		display: block;
		float: left;
		font-size: inherit;

		&:checked {
			+ label:before{
				background: $md-checkbox-checked-color;
				border:none;
			}
			+ label:after {

				$md-checkmark-size: $md-checkbox-size - 2 * $md-checkbox-padding;

				transform: translate($md-checkbox-padding, calc($md-checkbox-size / 2) - calc($md-checkmark-size / 2.6)) rotate(-45deg);
				width: $md-checkmark-size;
				height: calc($md-checkmark-size / 2);
			}
		}

		&:disabled {
			+ label:before{
				border-color: $md-checkbox-border-color-disabled;
			}
			&:checked {
				+ label:before{
					background: $md-checkbox-checked-color-disabled;
				}
			}
		}
	}

	// customization:
	// transparent background to box
	&.transparent-box label:before {
		background: transparent;
	}

	&.success-color {
		input[type="checkbox"]:checked + label:before {
			background: #25842d;
		}
	}
}

.general-actions {
	color: var(--gray2);
	transition: color 0.2s;
}

.input-holder.search-input {
	position: relative;
	input {
		margin: 0;
		padding: 8px 30px;
		font-size: 0.95rem;
		border-radius: 5px;
		box-sizing: border-box;
		border: 1px solid #ccc;
		width: 100%;
		outline: 0;
		&:hover {
			border: 1px solid dimgrey;
		}
		&:focus {
			border: 1px solid royalblue;
		}
	}
	.search-icon {
		position: absolute;
		top: 0;
		left: 0;
		padding: 10px 7px;
		line-height: 1;
		&:before {
			opacity: 0.3;
		}
	}
	.close-icon {
		position: absolute;
		right: 0;
		top: 0;
		padding: 10px;
		&:before {
			opacity: 0.3;
		}
		&:hover:before {
			color: var(--danger);
			opacity: 0.5;
		}
	}
}


// interactive hover effects

.hvr-scale:hover {
	transform: scale(1.03) translateZ(0); // trnaslateZ to prevent blur
	transition: transform 0.1s;
	backface-visibility: hidden;   // prevent blur
}

.hvr-opacity:hover {
	opacity: 0.8;
	transition: opacity 0.15s;
}

// usage: hvr-darken1, hvr-darken2, hvr-darken3
.hvr-darken {
	transition: filter 0.15s;
	&1:hover { filter: brightness(0.05); }
	&2:hover { filter: brightness(0.15); }
	&3:hover { filter: brightness(0.25); }
}

.cursor-pointer {
	cursor: pointer;
}

//

.shinyFX {
	z-index: 0;
	position: relative;
	background:transparent;

	&:after {
		content: '';
		top: 0;
		transform: translateX(100%);
		width: 100%;
		height: 100%;
		position: absolute;
		z-index: 1;
		animation: shineSlide 1s infinite ease-in;

		background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%); /* FF3.6+ */
		background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(50%, rgba(255, 255, 255, 0.8)), color-stop(99%, rgba(128, 186, 232, 0)), color-stop(100%, rgba(125, 185, 232, 0))); /* Chrome,Safari4+ */
		background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%); /* Chrome10+,Safari5.1+ */
		background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%); /* Opera 11.10+ */
		background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%); /* IE10+ */
		background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%); /* W3C */
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#007db9e8', GradientType=1); /* IE6-9 */
	}

	&-light {
		@extend .shinyFX;
		&:after {
			opacity: 0.5;
		}
	}
}

@keyframes shineSlide {
	0% {transform:translateX(-170%);}
	100% {transform:translateX(150%);}
}

.loading-spinner {
	width: 48px;
	height: 48px;
	border: 5px solid #FFF;
	border-bottom-color: transparent;
	border-radius: 50%;
	display: inline-block;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
	@keyframes rotation {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
}

// attachment UI code
.attachment-block-container {
	padding: 7px;
	border: solid 2px #e2e2e2;
	width: 160px;
	height: 50px;
	background: #f9f9f9;
	cursor: pointer;
	margin: 8px;
	position: relative;

	@media (max-width: 404px) {
		width: calc(100% - 16px);
	}

	.file-icon-container {
		align-items: center;
		border-radius: 5px;
		width: 32px;
		font-size: 15px;
		position: relative;
		color: var(--color-primary);
	}

	span.file-name {
		font-weight: bold;
		font-size: 0.75rem;
		flex: 1 0;
	}
	span.file-size {
		font-size: 0.7rem;
		color: grey;
	}

	.zmdi-download {
		font-size: 0.8rem;
	}
}

.outbound-quarantined-tracking-wrapper {
	.track-events-wrapper {
		.events {
			height: auto !important;
		}
	}

	.chain
	{
		.icon-box {
			background: var(--bg-primary);
			&:after {
				left: 36px !important;
				width: 80px !important;
			}
			// v icon aside tracking icons padding
			&.postmark, &.delivery {
				&.active:before, &.current:before {
					@media (max-width: 768px) {
						left: 46px !important;
					}
				}
			}
			&.reply {
				&.active:before, &.current:before {
					@media (max-width: 768px) {
						left: 45px !important;
					}
				}
			}
			&.active:before, &.current:before {
				@media (max-width: 768px) {
					left: 50px !important;
				}
			}
			//
		}
		@media screen and (max-width: 768px){
			justify-content: space-around !important;
		}
	}
}

.ng2-pdf-viewer-container {
	.page {
		width: 100%!important;

		.canvasWrapper {width:100%!important};
		.textLayer {width:100%!important};
	}
}

.shadow {
	box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important
}

.widthAnimation {
	animation: widthAnimation 0.5s ease;
}

@-webkit-keyframes widthAnimation {
	from {
		width: 0;
	}
}

@-moz-keyframes widthAnimation {
	from {
		width: 0;
	}
}

@-o-keyframes widthAnimation {
	from {
		width: 0;
	}
}

@keyframes widthAnimation {
	from {
		width: 0;
	}
}

.drop-menu-container {
	position: relative;

	&:hover {
		.drop-menu {
			visibility: visible;
			opacity: 1;
			transform: translateY(0);
		}
	}

	.drop-menu {
		z-index: 1040;
		visibility: hidden;
		opacity: 0;
		transform: translateY(5px);
		width: 100%;
		min-width: 180px;
		top: calc(100% + 5px);
		padding-top: 5px;
		right: 0;
		background: var(--bg-secondary);
		position: absolute;
		margin: 0;
		border-radius: 4px;
		box-shadow: 0 1px 6px rgba(0, 0, 0, .8);
		transition: all .1s ease-out;

		hr {
			margin: 0;
			width: 100%;
			opacity: 0.07;
			position: relative;
		}

		// this is meant to expand the hovering area of the drop-menu so it want close when hovering just between the profile button and this menu
		&:before {
			content: "";
			top: -5px;
			position: absolute;
			width: 100%;
			height: 5px;
			cursor: default;
		}

		// triangle at top
		&:after {
			content: "▲";
			top: -12px;
			transform: scale(1.7, 1.1);
			text-align: right;
			right: 75px;
			position: absolute;
			width: 100%;
			height: 5px;
			cursor: default;
			color: white;
		}

		ul {
			width: 100%;
			margin: 0;
			padding: 0;
			list-style: none;

			li {
				text-align: left;
				width: 100%;
				padding: 13px 15px;
				font-size: 13px;
				margin: 0;
				color: black;
				border-radius: 4px;
				transition: background .1s ease-out;

				.fa, .fas, .far, .zmdi {
					font-size: 13px;
					flex-basis: 15px;
					color: dimgray;
				}

				.fa-external-link-alt {
					position: absolute;
					right: 0;
					font-size: 9px;
					color: slategrey;
				}

				&:not(.static-menu-item):hover {
					background: rgba(204, 204, 204, 0.15) !important;
					color: var(--bs-primary);
				}

				&.expandable {
					position: relative;
					padding-right: 12px;

					.sub-menu {
						visibility: hidden;
						opacity: 0;
						left: -1px;
						right: unset;
						transform: translateX(-100%) translateY(0);
						box-shadow: -1px 1px 3px grey;
						top: 0;
						transition: all .1s ease-out;

						&:after, &:before {
							content: none;
						}
					}

					&:hover {
						.sub-menu {
							visibility: visible;
							opacity: 1;
						}
					}

					&:after {
						content: "▾";
						position: absolute;
						right: 10px;
						font-size: 15px;
						transform: scale(1.5, 1);
					}
				}
			}

			li.static-menu-item {
				background: #fafafa;
				padding: 15px;
				cursor: default;
			}
		}

		&.show-above {
			top: auto;
			bottom: calc(100% + 5px);
			transform: translateY(-5px);

			&:before {
				bottom: -5px;
				top: auto;
			}

			&:after {
				content: "▼";
				bottom: -12px;
				top: auto;
			}
		}

		&.to-left {
			left: 0;
			right: auto;
			// triangle at top
			&:after {
				left: 75px;
				right: auto;
			}
		}
	}
}
.dark-mode-div {
	cursor: pointer;
	position: absolute;
	top: 0;
	right: 57px;
	margin: 5px;
	padding: 5px 5px 4px 5px;
	border-radius: 4px;
	border: 1px solid var(--light-gray);
	transition: all 0.15s;
}
.desktop-header .dark-mode-div,
.mobile-header .dark-mode-div {
	padding: 6px;
}
.language-select {
	width: fit-content;
	position: absolute;
	right: 0;
	top: 0;
	padding: 7px;
	border-radius: 5px;
	border: solid 1px; // border color is inside ngStyle attribute
	margin: 5px;
	font-size:12px;
	font-weight: bold;
	transition: all 0.15s;

	.drop-menu {
		width: 90px;
		margin-top: 3px;
		min-width: 0;
		> ul > li {
			cursor: pointer;
		}

		&:before {
			display: none !important;
		}

		&:after {
			top: -7px !important;
			right: 5px !important;
			width: auto;
		}

		&:not(.active) {
			visibility: hidden !important;
			opacity: 0 !important;
			transform: translateY(5px) !important;
		}

		&.active {
			visibility: visible !important;
			opacity: 1 !important;
			transform: translateY(0) !important;
		}
	}
}

.advanced-wrapper .switch-toggle {
	font-size: 28px !important;
}

.session-expired {
	.logo-container {
		z-index: 20;

		.header-wrapper {
			display: contents;
		}
		.logo {
			display: none;
		}
	}
}

code-input {
	--text-security-type: disc;
	--item-spacing: 3% !important;
	--item-height: 100% !important;
	--item-shadow-focused: none !important;
	--item-background: white !important;
	--color: #486db5 !important;

	input {
		text-align: center;
		font-size: inherit;
		font-weight: bold;
		color: #486db5;
		font-family: monospace;
		border: 1px solid #ccc !important;

		&:focus {
			border: solid 1px #1f61ee !important;
		}
	}

	&.incorrect-pin {
		input {
			border-color: red !important;
		}
	}
}

.blue-link {
	color: royalblue !important;
	&:not(.disabled):hover {
		color: darken(royalblue,45%) !important;
	}
	&.disabled {
		color: #818181 !important;
	}
}

virtual-scroller.is-loading-or-empty {
	max-height: 0;

	thead {
		transform: none !important;
	}

	.total-padding {
		display: none !important;
	}
}

.centralize {
	justify-content: center;
	align-items: center;
}

.f-1-0 {
	flex: 1 0;
}

.t-bg-primary {
	background-color: var(--bg-primary);
}

.t-bg-secondary {
	background-color: var(--bg-secondary);
}

.t-bg-lf-color {
	background-color: var(--bg-secondary);
}

.t-color-primary {
	color: var(--color-primary);
}
.t-color-secondary {
	color: var(--gray2);
}
.t-color-danger {
	color: var(--danger);
}
.t-color-warning {
	color: var(--warning);
}
.t-color-success {
	color: var(--success);
}

.validate-btn {
	width: 280px;
	max-width: 60%;
	min-height: 40px;
	.simple-btn {
		text-transform: uppercase;
		font-size: calc(0.7rem + 0.2vw) !important;
		> * {
			font-weight: 500;
		}

		&.disabled {
			background: #7e7e7e !important;
		}
	}
}

btn.large-btn .simple-btn {
	height: 35px;
	min-width: 120px;
}

.trustifi-table {
	$bgClr: white;
	$secondaryClr: hsla(var(--bs-primary-h), 100%, 50%, 0.011);

	overflow: auto;
	display: flex;
	flex-direction: column;

	.listTable {
		&.alternated {
			tbody:nth-child(odd) {
				background-color: $secondaryClr;
			}

			tbody:nth-child(even) {
				background-color: transparent;
			}

			tr:nth-child(odd) {
				background-color: $secondaryClr;
			}

			tr:hover {
				background-color: darken($bgClr, 3%);
			}
		}
	}

	&.nested-table {
		.listTable {
			border: none !important;
			height: auto !important;
		}
		table {
			background: transparent !important;
		}
		th {
			height: 0 !important;
			padding: 0 !important;
			* {
				height: 0 !important;
				max-height: 0 !important;
				visibility: hidden !important;
			}
		}
		.table-select-radio {
			visibility: hidden;
			pointer-events: none;
		}
		tr {
			background-color: transparent;
			&:hover {
				background-color: darken($bgClr, 2%);
			}
		}
		tbody {
			background-color: transparent !important;
		}
	}

	.inner-search-input:not(:last-child) {
		border-right: solid 1px #cccccc;
	}

	&.short-action-column .actions-column-header {
		width: 140px !important;
	}
}

.listTable-c {
	$bgClr: white;
	$secondaryClr: hsla(var(--bs-primary-h), 100%, 50%, 0.02);

	overflow: auto;
	display: flex;
	flex-direction: column;
	border: 1px solid #ededed;
	width: 100%;
	height: 300px;
	color: var(--bs-dark);

	table {
		table-layout: fixed;
		width: 100%;
		border-collapse: collapse;
		background-color: $bgClr;

		input {
			width: 100%;
		}

		th,
		td {
			height: 50px;
			padding: 10px 0 10px 10px;
		}

		tbody {
			border-bottom: solid 1px #f1f1f1;

			tr:not(:last-child) {
				border-bottom: solid 1px #f1f1f1;
			}
		}

		tbody tr {
			cursor: pointer;
			transition: background-color 0.1s;

			&:hover {
				background-color: darken($bgClr, 1%);
			}

			&.chosen {
				background-color: hsl(var(--bs-primary-h), var(--bs-primary-s), 95%) !important;

				& + .chosen {

				}
			}

			&.chosen-solo {
				border-top: 2px solid rgb(139, 152, 177) !important;
				border-bottom: 2px solid rgb(139, 152, 177) !important;
				background: white !important;
			}

			&:first-child.chosen-solo {
				border-top: none !important;
			}

			&.sub-row {
				background: #f8f8f8 !important;
			}
		}

		tr.is-dragged-over {
			position: relative;

			> *:last-child {
				position: unset;

				&:after {
					content: "";
					position: absolute;
					top: -1.5px;
					left: 0;
					right: 0;
					height: 0;
					border-top: solid 3px royalblue;
				}
			}
		}
		tr.is-dragged + tr.is-dragged-over > *:last-child:after {
			content: none !important;
		}

		thead {
			position: relative;
			z-index: 50;

			th {
				font-weight: 500;
				text-align: left;
				position: relative;
				z-index: 1500;
				top: 0;
				height: 42px;
				background-color: hsl(var(--bs-primary-h), 40%, 92%);
			}
		}

		.table-select-radio {
			&.md-checkbox {
				label {
					margin-left: 0 !important;

					&:before {
						// box
						width: 15px;
						height: 15px;
						background: #fff;
						border: 1px solid rgba(0, 0, 0, 0.45);
					}

					&:after {
						// checkmark
						width: 9px !important;
						height: 4.5px !important;
					}
				}

				input[type="checkbox"]:checked + label:after {
					border-width: 2px !important;
				}
			}
		}
	}

	&.alternated {
		table {
			tbody:nth-child(odd) {
				background-color: $secondaryClr;
			}

			tbody:nth-child(even) {
				background-color: transparent;
			}

			tr:nth-child(odd) {
				background-color: $secondaryClr;
			}

			tr:hover {
				background-color: darken($bgClr, 3%);
			}
		}
	}

	// custom tables:
	&.no-index {
		table {
			td, th {
				padding: 10px;
			}

			tr {
				cursor: default
			}
		}
	}

	&.no-header {
		table {
			th {
				padding: 0 !important;
				height: 0 !important;

				> * {
					display: none !important;
				}
			}
		}
	}

	&.ellips {
		table {
			span {
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				padding-right: unset;
			}

			td > span:only-child, .external-html-cell > span:only-child {
				display: block;
				width: fit-content;
				max-width: 100%;
			}

			th:not(.overflownV),
			td:not(.overflownV) {
				overflow: hidden;
				text-overflow: ellipsis;

				div {
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
		}
	}

	&.short-rows {
		table {
			td {
				height: 45px;
				padding: 7px 0 7px 10px;
			}
		}
	}

	// changes to scrollbar
	/*&::-webkit-scrollbar {
	  width: 6.5px;
	}

	&::-webkit-scrollbar-track {
	  border: none;
	  border-radius: 0;
	  border-left: solid 1px #f2f2f2;
	}*/

	&.nested-table {
		.listTable {
			border: none !important;
			height: auto !important;
		}

		table {
			background: transparent !important;
		}

		th {
			height: 0 !important;
			padding: 0 !important;

			* {
				height: 0 !important;
				max-height: 0 !important;
				visibility: hidden !important;
			}
		}

		.table-select-radio {
			visibility: hidden;
			pointer-events: none;
		}

		tr {
			background-color: transparent;

			&:hover {
				background-color: darken($bgClr, 2%);
			}
		}

		tbody {
			background-color: transparent !important;
		}
	}

	.external-html-cell {
		&:empty {
			display: none;
		}
	}
}

// We clone the header and place the cloned header above the scrollable area since we can't stick it to the top of the table because of the virtual scrolling mechanism
virtual-scroller > .scrollable-content > table > thead th {
	height: 0 !important;
	padding-top: 0 !important;
	padding-bottom: 0 !important;
	margin: 0 !important;
	line-height: 0 !important;
	color: transparent !important;

	> * {
		display: none !important;
	}
}
.listTable-c > table {
	position: sticky;
	top: 0;
	z-index: 30;

	thead {
		transform: none !important;
	}
}

.absolute-centralize {
	position: absolute;
	top: 50%;
	left: 50%;
	right: auto;
	bottom: auto;
	transform: translate(-50%, -50%);
}

.hide-when-empty:empty {
	display: none!important;
}

.leaflet-map-container {
	.leaflet-marker-shadow {
		display: none !important;
	}
}


.leaflet-control-attribution {
	display: none!important;
}
.leaflet-control-zoom-in, .leaflet-control-zoom-out {
	text-decoration: none !important;
}


.radio-container {
	display: block;
	position: relative;
	padding-left: 25px;
	margin-bottom: 12px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	/* Hide the browser's default radio button */
	input {
		position: absolute;
		left: 0;
		opacity: 0;
		cursor: pointer;
	}
	/* Create a custom radio button */
	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 15px;
		width: 15px;
		border-radius: 50%;
		border: 1px solid;
	}

	&:hover input ~ .checkmark {
		background-color: #ccc;
	}
	/* Create the indicator (the dot/circle - hidden when not checked) */
	.checkmark:after {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		border: solid 2px white;
		border-radius: 50%;
		display: none;
	}

	/* Show the indicator (dot/circle) when checked */
	input:checked ~ .checkmark:after {
		display: block;
	}
}

.checkbox-button {
	&:not(.radio-style) {
		@extend .md-checkbox;

		input[type="checkbox"] {
			height: 0;
		}

		> label[for] {
			color: inherit;
			margin: 0 !important;
			padding-left: 2em;
		}
	}

	border: solid 1px var(--light-gray);
	padding: 7px 10px;
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
	background: var(--bg-primary);
	color: var(--gray-dark);
	transition: all 0.2s;
	border-radius: 0.5em;

	&.selected {
		background: var(--bs-primary);
		color: var(--bs-text-color);
	}

	&:hover {
		background: hsla(var(--bs-primary-h), var(--bs-primary-s), 70% ,0.1);
		&.selected {
			background: hsla(var(--bs-primary-h), var(--bs-primary-s), var(--bs-primary-l), 0.9);
		}
	}

	> label.radio-container {
		color: inherit;
		margin: 0 !important;
		pointer-events: none;

		input:checked ~ .checkmark {
			background-color: white !important;
			border-color: white !important;

			&:after {
				border: solid 2px var(--bs-primary) !important;
			}
		}
	}

	input[type="checkbox"]:checked + label:before {
		border: solid 2px var(--bs-text-color);
	}
}

$md-checkbox-checked-color: #{var(--bs-primary)};
$md-checkbox-border-color: rgba(0, 0, 0, 0.54);
$md-checkbox-border-color-disabled: rgba(0, 0, 0, 0.26);
$md-checkbox-checked-color-disabled: rgba(0, 0, 0, 0.26);

$md-checkbox-margin: 0;
$md-checkbox-size: 1.25em;
$md-checkbox-padding: .25em;
$md-checkbox-border-width: 2px;
$md-checkbox-border-radius: 0.125em;
$md-checkmark-width: 0.2em;
$md-checkmark-color: #fff;
$md-checkbox-label-padding: 1.75em;
$md-checkmark-size: $md-checkbox-size - 2 * $md-checkbox-padding;

.md-checkbox {
	position: relative;
	margin: $md-checkbox-margin;
	text-align: left;

	&.md-checkbox-inline {
		display: inline-block;
	}

	label:first-of-type {
		cursor: pointer;
		display: inline;
		position: relative;
		line-height: $md-checkbox-size;
		vertical-align: top;
		clear: both;
		padding-left: 1px;
		&:not(:empty) {
			padding-left: $md-checkbox-label-padding;
		}

		&:before, &:after {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
		}

		&:before {
			// box
			width: $md-checkbox-size;
			height: $md-checkbox-size;
			background: #fff;
			border: $md-checkbox-border-width solid $md-checkbox-border-color;
			border-radius: $md-checkbox-border-radius;
			cursor: pointer;
			transition: background .3s;
		}

		&:after {
			// checkmark
		}
	}

	input[type="checkbox"] {
		outline: 0;
		visibility: hidden;
		width: 0;
		margin: 0;
		display: block;
		float: left;
		font-size: inherit;

		&:checked {
			+ label:before{
				background: $md-checkbox-checked-color !important;
				border:none !important;
			}
			+ label:after {
				transform: translate($md-checkbox-padding, calc($md-checkbox-size / 2) - calc($md-checkmark-size / 2.6)) rotate(-45deg);
				width: $md-checkmark-size;
				height: calc($md-checkmark-size / 2);

				border: $md-checkmark-width solid $md-checkmark-color;
				border-top-style: none;
				border-right-style: none;
			}
		}

		&:disabled {
			+ label:before{
				border-color: $md-checkbox-border-color-disabled;
			}
			&:checked {
				+ label:before{
					background: $md-checkbox-checked-color-disabled;
				}
			}
		}
	}

	// customization:
	// transparent background to box
	&.transparent-box label:before {
		background: transparent;
	}

	&.success-color {
		input[type="checkbox"]:checked + label {
			&:before {
				background: var(--bs-success) !important;
			}
			&:after {
				border-color: white !important;
			}
		}
	}

	&.danger-color {
		input[type="checkbox"]:checked + label {
			&:before {
				background: var(--bs-danger) !important;
			}
			&:after {
				border-color: white !important;
			}
		}
	}

	&.negative-checkbox input[type="checkbox"] {
		&:checked {
			+ label:after {
				content: "X";
				color: var(--bs-text-color);
				font-weight: bold;
				transform: none;
				width: $md-checkmark-size;
				height: $md-checkmark-size;

				border: none !important;
				font-size: 12px;
				font-family: cursive;
				padding-left: 4px;
			}
		}
	}

	&.colorful-border {
		input[type="checkbox"] + label:before {
			border-color: $md-checkbox-checked-color;
		}

		&.success-color input[type="checkbox"] + label:before {
			border-color: rgba(var(--bs-success-rgb), 0.3) !important;
		}

		&.danger-color input[type="checkbox"] + label:before {
			border-color: rgba(var(--bs-danger-rgb), 0.6) !important;
		}
	}
}

.scrollable-content {
	max-width: inherit!important;
}

.radial-progress-bar {
	position: relative;

	.p-bar {
		height: 100%;
		width: 100%;
		border-radius: 50%;
	}

	&:after {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		background: white;
		width: calc(100% - clamp(7px, 20%, 15px));
		height: calc(100% - clamp(7px, 20%, 15px));
		border-radius: 50%;
		transform: translate(-50%, -50%);
		z-index: 7;
	}

	/*&:before {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		background: conic-gradient(#757575, white);
		width: calc(calc(100% - clamp(7px, 20%, 15px)) + 2px);
		height: calc(calc(100% - clamp(7px, 20%, 15px)) + 2px);
		border-radius: 50%;
		transform: translate(-50%, -50%);
		z-index: 6;
		animation: radial-progress-spinner 1s linear infinite;
	}*/
}

.flex-row, .flex-column, .flex-row-reverse, .flex-column-reverse {
	display: flex !important;
}

/* Safari 10.1 - 14.1 */
@media not all and (min-resolution: 0.001dpcm) { // from 10.1
	@supports (not (translate: none)) { // until 14.1
		@for $i from 1 through 5 {
			.gap-#{$i}.flex-column:not(.flex-wrap), .gap-#{$i}[class^='flxClmn']:not(.flex-wrap) {
				> * {
					margin: calc(2.75px * #{$i}) 0;

					&:first-child {
						margin-top: 0;
					}

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
			.gap-#{$i}:not(.flex-column):not([class^='flxClmn']):not(.flex-wrap):not(.flex-row-reverse) {
				> * {
					margin: 0 calc(2.75px * #{$i});

					&:first-child {
						margin-left: 0;
					}

					&:last-child {
						margin-right: 0;
					}
				}
			}
			.gap-#{$i}.flex-row-reverse {
				> * {
					margin: 0 calc(2.75px * #{$i});

					&:first-child {
						margin-right: 0;
					}

					&:last-child {
						margin-left: 0;
					}
				}
			}
			.gap-#{$i}.flex-wrap {
				> * {
					margin: calc(2.75px * #{$i});
				}
			}
		}
		.app-dropdown-menu-container {
			will-change: filter;
		}
		.md-tabs, .settings-list-item {
			flex-shrink: 0;
		}
	}
}

attachment-c {
	max-width: 100%;
}

attachment-preview {
	display: contents;
}

router-outlet, router-outlet + * {
	display: contents;
}

.handled-banner {
	max-width: 45px;
	transition: max-width 0.25s;

	&:hover {
		max-width: 500px;
	}
}

.shader {
	position: absolute;
	// stretch:
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	//
	background: black;
	opacity: 0;
	visibility: hidden;
	z-index: 100;
	transition: opacity 0.25s;

	&.active {
		visibility: visible;
		opacity: 0.5;
	}

}

.validation-error {
	border: solid 1px #ff234c !important;
}

.blocked-page-actions {
	flex: 2 0;
	align-items: center;
	color: dimgrey;
	padding: 10px;
	font-weight: bold;

	span {
		text-align: center;
	}

	.notification-label {
		background: #00000008;
		padding: 15px;
		min-width: 320px;
	}
}

.email-outer-container {
	position: relative;
	width: 100%;
	border: solid 2px #e3e4e4;
	border-left: none;
	border-right: none;
}

.expand-button {
	position: absolute;
	opacity: 1;
	left: 0;
	bottom: 0;
	cursor: pointer;
	width: 35px;
	height: 35px;
	background: rgba(255,255,255,0.85);
	border: solid 0 #e3e4e4;
	border-radius: 3px;
	margin: 0;
	transition: background 0.2s;
	i {
		opacity: 0.85;
		transition: transform 0.2s , color 0.2s;
	}
	&:hover {
		background: rgba(255,255,255,1);
		i {
			transform: scale(1.1);
		}
	}
}

.threat-protection-grid {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; //(sum: 24)
	// grid-gap: 1.5vw;

	@for $i from 1 through 24 {
		// normal:
		.co#{$i} {
			grid-column: span #{$i};
		}
		.ro#{$i} {
			grid-row: span #{$i};
		}
		.co0 {
			display: none;
		}
		// medium:
		.md-co#{$i} {
			@media screen and (max-width: 1550px) {
				grid-column: span #{$i};
			}
		}
		.md-ro#{$i} {
			@media screen and (max-width: 1550px) {
				grid-row: span #{$i};
			}
		}
		.md-co0 {
			@media screen and (max-width: 1550px) {
				display: none;
			}
		}
	}
}

.threat-protection-card {
	border-radius: 8px;
	background: var(--bg-primary);
	box-shadow: 0 0 8px darkgrey;
	overflow: auto;
	margin: 0.75vw;

	.card-header {
		background: linear-gradient(90deg, #f5f7fd, var(--bg-primary));
		border-bottom: solid 1px lightgrey;
		padding: 15px;
	}
	.card-title {
		// font-family: 'Roboto Condensed', sans-serif;
		font-size: calc(0.45rem + 0.4vw);
		color: var(--gray-dark);
		font-weight: bold;
	}

	::-webkit-scrollbar {
		width: 0.4rem;
	}
}

.email-content-card {
	min-height: 770px;
	.show-email-medium-btn {
		display: none !important;
	}
}

.threats-email-toggle-container {
	height: 30px;
	right: 25px;
	position: absolute;
	width: auto;
	margin: 0;
	top: 16px;
	font-size: 13px;
	font-family: 'Roboto', sans-serif;
}

.threats-email-toggle {
	align-items: center;
	cursor: pointer;
	border: solid 2px #e3e4e4;
	border-bottom: solid 1px transparent;
	width: 6.5rem;
	z-index: 5;
	transition: color 0.2s;

	&.chosen {
		font-weight: 400;
	}
}

.threats-email-toggle-container-mobile {
	height: 40px;
	right: 25px;
	margin: 4px 0 0 0;
	width: 100%;
	font-family: 'Roboto', sans-serif;
	background: var(--bg-secondary);
	padding: 5px;
	font-size: 0.9rem;
}

.threats-email-toggle-mobile {
	padding: 10px;
	cursor: pointer;
	border: solid 1px dimgrey;
	border-radius: 5px;
	width: 40vw;
	z-index: 5;
	transition: color 0.2s;
	font-size: 13px;

	&.chosen {
		font-weight: 400;
	}
}

.confirm-text {
	font-size: 13px;
	ul {
		margin-left: 15px;
		padding-left: 0;
	}
}

.reviewer-actions-window {
	.reply-inner-container {
		padding: 25px;
		font-size: 14px;
	}
	.table-outer-container {
		background: var(--bg-secondary);
		padding: 1vw;
	}
	.table-inner-container {
		background: var(--bg-primary);
		border: solid 1px #e3e4e4;
	}
	.reviewer-actions-table {
		table-layout: fixed;
		padding: 0 10px;
		font-size: 0.85rem;
	}
	// cellular devices adjustment (landscape and portrait)
	@media screen and (max-width: 479px),
	screen and (max-height: 479px) {
		.reply-inner-container {
			padding: 0;
		}
		.title-row {
			padding: 5vw;
		}
		.table-outer-container {
			padding: 1vw;
		}
		.table-inner-container.not-empty {
			min-width: 550px;
		}
		.reviewer-actions-table {
			font-size: 12px;
		}
	}
}

.tp-popup-sidebar {
	width: 300px;
	box-shadow: 0 0 5px grey;
	z-index: 5;
	background: linear-gradient(45deg, var(--light-gray-at), transparent);
	position: sticky;
	left: 0;
	top: 0;
}

.tp-popup-header {
	max-height: 100px;
	padding: 10px 20px;
	border-bottom: solid 1px lightgrey;
	z-index: 100;
	h3 {
		font-size: 1.4rem;
	}
}

.fa-copy {
	color: var(--color-primary);
	&:hover {
		filter: opacity(0.7);
	}
	&:not(:active) {
		// now keep green color for 1.5s
		transition: color 3000ms step-end, opacity 75ms step-end;
		cursor: pointer;
	}
	&:active {
		color: #008b00 !important;
		opacity: 0.1 !important;
	}
}

.card-header {
	padding: 11px;
}

// laptop-size screens:
@media screen and (max-width: 1550px) {
	.tp-popup-header {
		h3 {
			font-size: 1.1rem !important;
		}
	}

	.tp-popup-sidebar {
		width: 260px;
	}

	.threat-protection-grid {
		padding-top: 10px !important;
	}
}

// cellular devices adjustment (landscape and portrait)
@media screen and (max-width: 479px),
screen and (max-height: 479px) {
	.tp-popup-header {
		max-height: none !important;
		background: linear-gradient(180deg, var(--light-gray), var(--bg-primary), var(--bg-primary)) !important;;
	}

	.threat-protection-grid {
		padding-top: 0 !important;
		display: block !important;
		height: auto !important;
		max-height: none !important;
	}

	.threat-protection-card {
		grid-column: span 24 !important;
		margin: 0 0 15px;
		border: solid 1px #e3e4e4;
		border-radius: 0;
		max-height: none !important;
		box-shadow: none;
		height: auto;
		min-width: unset!important;
		min-height: 150px !important;
		flex: unset !important;
	}

	.actions-details-container {
		position: fixed !important;
		z-index: 500 !important;
		top: 0;
		.attachments-container {
			max-height: 300px !important;
			line-height: 1.5;
			transform: translate(0,100%) !important;
			border-radius: 0 !important;
		}
		.shader {
			display: block !important;
		}

		&.active {
			.attachments-container {
				transform: translate(0,0) !important;
			}
		}
	}

	.card-title {
		font-size: 14px !important;
	}

	.tp-popup-sidebar {
		box-shadow: none !important;
		width: 100% !important;
		border: none !important;
		border-bottom: solid 1px #e3e4e4 !important;
		margin: 0 !important;
		background: linear-gradient(180deg, var(--bg-primary), var(--light-gray-at)) !important;
	}

	.shader {
		display: none;
	}

	.tp-below-header-container {
		flex-direction: column !important;
	}

	.email-content-card:after {
		display: none;
	}

	.email-content-card {
		order: unset !important;
		display: flex !important;
		grid-column: span 24 !important;
		min-height: 700px !important;
		margin: 0 !important;
		max-height: none !important;
		height: auto;
		.threat-protection-card {
			display: flex !important;
			flex: 1 0 !important;
		}
		.show-email-large-btn {
			display: none !important;
		}
	}

	.email {
		display: flex;
	}

	.email-view {
		padding: 0
	}

	.reply-outer-container {
		transform: translateX(-100%);
		max-height: none;
		min-height: 0;
		height: 100%;
		transition: transform 0.25s ease-out;
		box-shadow: none;
		overflow: hidden;
		.reply-inner-container {
			height: 100%;
		}

		&.active {
			position: fixed; // show over all content
			max-height: none !important;
			min-height: 0;
			transform: translateX(0);
		}
		&.minimized {
			max-height: none;
			min-height: 0;
			transform: translateX(-100%);
		}

		@media screen and (max-height: 479px) {
			overflow: auto;
			.reply-inner-container {
				height: auto;
			}
		}
	}

	.reply-header-image {
		padding: 0;
	}

	.expand-button {
		display: none !important;
	}

	.content-inner-container{
		flex: 1 0;
	}
}

.tp-below-header-container {
	display: flex;
	flex-direction: row;
}

.message-analysis-table {
	td {
		padding: 7px 10px !important;
		& > span.ellipsis {
			display: block;
		}
	}
	tr:nth-child(even) {
		background: rgba(240,248,255,0.22);
	}
	.fa-check {
		color: var(--success);
		font-size: 0.85rem;
	}
	.fa-times-circle{
		color: var(--danger);
		font-size: 0.85rem;
	}
	.fa-exclamation-circle {
		color: var(--warning);
		font-size: 0.85rem;
	}
}

.actions-details-container {
	overflow: hidden;
	width: 100%;
	height: 100%;
	position: absolute;
	visibility: hidden;
	backdrop-filter: blur(3px);

	> .attachments-container {
		background: linear-gradient(20deg, var(--light-blue), var(--bg-primary));
		box-shadow: 0 0 10px dimgrey;
		border-radius: 5px;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		max-width: 800px;
		z-index: 200;
		transition: transform 0.2s ease-out;
		transform: translateX(-100%);
	}

	&.active {
		.attachments-container {
			transform: translateX(0);
		}
		visibility: visible;
	}
}

.action-buttons-container {
	btn {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		max-height: none;
		flex: 0 0 45%;
		min-width: fit-content;
		font-size: 12px;
		margin: 5px;
		white-space: nowrap;
	}
}

.email-content-card:after {
	content: "";
	position: absolute;
	top: -1.5vw;
	bottom: -0.75vw;
	left: -0.75vw;
	right: -1.5vw;
	z-index: 2;
	// border: solid 1px lightgrey;
}

.email-view {
	width: 100%;
	// background: #f2f2f2;
	padding: 5px 1px;
	overflow: auto;

	&::-webkit-scrollbar-track {
		margin-bottom: 50px;
	}
}

.attachments-container {
	background: #f2f2f2;
	border: solid 1px #e3e4e4;
	max-height: 110px;
	transition: max-height 0.25s ease-out;
	&.collapsed {
		max-height: 0;
	}

	.hover-link {
		transition: color 0.2s, opacity 0.2s;
		&:hover { color: darken(#4d4d4d,5%); opacity: 1; }
		&:active { color: darken(#4d4d4d,50%); opacity: 1; }
		&.clickable:hover {
			text-decoration: underline;
		}
	}
}

.reply-outer-container {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height: 100%; // for slide-in animation
	max-height: 0;
	background: var(--bg-primary);
	box-shadow: 0 0 10px rgba(0,0,0,0.6);
	opacity: 1;
	z-index: 101;
	transition: max-height 0.25s ease-out,  min-height 0.25s ease-out;
	min-height: 0;

	&.active {
		max-height: 82%;
		min-height: 565px;
	}

	&.minimized {
		max-height: 0;
		min-height: 0;
	}
}

.reply-inner-container {
	padding: 15px;

}

.email {
	display: block; // (flexbox causes issues in IE iFrames...)
	flex-direction: column;
	height:100%;

	.email-iframe {
		height:100%;
		width: 100%;
		border: 0;
		html {
			padding: 0 35px;
		}
	}
}

.attachments-body-container {
	background: #f9f9f9;
	overflow: auto;
	margin: 5px 1rem;
	width: calc(100% - 1rem)
}

.content-inner-container {
	padding: calc(10px + 0.2vh) calc(5px + 0.5vw);
}

.buttons-area {
	margin: 1rem 0;
	animation: expandFX 0.5s forwards ease-out;
	@keyframes expandFX {
		0% {
			max-height:0;
			opacity: 0;
		}
		99% {
			max-height:36px;
			opacity: 1;
		}
		100% {
			max-height:none;
			opacity: 1;
		}
	}
}

app-dropdown {
	&.show-above .app-dropdown-menu-container{
		bottom: auto;
		top: -1px;
		box-shadow: 0 -1px 2px #bcbcbc;
		transform: translateY(-100%);
	}

	&.limit-height .app-dropdown-menu-container ul{
		max-height: 300px !important;
		overflow: auto !important;
	}

	&.w-ellipsis {
		.md-checkbox > label:first-of-type {
			display: block !important;
			@extend .ellipsis;
		}
	}
}

.app-dropdown {
	$dd-border-radius: 5px;
	border: solid 1px var(--light-gray);
	border-radius: $dd-border-radius;
	cursor: pointer;
	overflow: hidden;
	position: relative;
	transition: box-shadow 0.1s;

	.app-dropdown-box {
		z-index: 15;
		border-radius: $dd-border-radius;
		transition: all 0.1s;

		> .fa-chevron-down {
			font-size: 0.7rem;
			transition: background 0.15s;
		}

		.search-input {
			margin: 0;
			width: auto;
			max-width: 100%;
			border: 0;
			outline: none;
			border-radius: 5px;
			height: 100%;
			background: var(--bs-primary);
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 25px;
		}

		&:after { // for hover effect when L&F
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			background: var(--primary-color);
			border-radius: 5px;
			opacity: 0;
			transition: all 0.1s;
			z-index: 1;
		}
		> * {
			z-index: 10;
		}
	}

	.app-dropdown-menu-container {
		height: auto;
		width: var(--dropdown-menu-width); // default: 100%. (customable through container)
		// max-height: 300px;
		z-index: 10;
		position: absolute;
		bottom: -2px;
		left: 0;
		right: 0;
		top: auto;
		transform: translateY(100%);
		background: var(--bg-primary);
		border: solid 1px var(--light-gray);
		border-top: none;
		border-radius: $dd-border-radius;
		border-top-left-radius: 2px;
		border-top-right-radius: 2px;
		box-shadow: 0 1px 2px var(--light-gray-at);

		visibility: hidden;
		opacity: 0;
		transition: visibility 0.05s, opacity 0.05s;

		virtual-scroller {
			overflow: var(--bs-dropdown-overflow);
			max-height: 320px;
		}

		ul {
			overflow: var(--bs-dropdown-overflow);
			max-height: var(--bs-dropdown-max-height);
			width: 100%;
			margin: 0;
			padding: 0;
			list-style: none;
			li {
				height: auto;
				width: 100%;
				margin: 0;
				padding: 10px;
				max-height: none;
				min-height: 35px;
				border: none;
				text-align: left;
				cursor: pointer;
				transition: all .2s ease-in-out, visibility 0s; // visibility 0 for submenus
				> span { // avoid trustifi-table "ellipsis" rule
					white-space: pre-wrap;
				}
				&.ellipsis {
					span {
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}

				&:hover:not(.static-item):not(.selected):not(.disabled) {
					background: rgba(245, 245, 245, 0.35);
					// color: var(--bs-primary);
					.submenu {
						visibility: visible;
					}
				}
				&.disabled {
					cursor: not-allowed;
					opacity: 0.6;
					&:active {
						pointer-events: none;
					}
				}
				&.static-item {
					background: var(--bg-secondary) !important;
					cursor: default !important;
				}

				// with submenu
				&.has-submenu {
					position: relative;
					padding-right: 22px;

					> ul.submenu {
						position: absolute;
						visibility: hidden;
						background: var(--bg-primary);
						opacity: 0;
						width: 100%;
						border-top: solid 1px var(--light-gray-at);
						height: auto;
						max-height: none;
						top: 0;
						bottom: auto;
						transition: all .05s ease-out;

						> li { // avoid parent li's hover effect
							color: var(--color-primary);
						}

						&, &.drop-left { // default
							left: -1px;
							right: auto;
							transform: translateX(-100%);
							box-shadow: -2px 0 5px var(--light-gray-at);
						}
						&.drop-right {
							left: auto;
							right: 0;
							box-shadow: 2px 0 5px var(--light-gray-at);
						}
					}

					&:hover {
						> ul.submenu {
							visibility: visible;
							opacity: 1;
						}
					}

					&:after {
						content: "▾";
						position: absolute;
						right: 10px;
						font-size: 15px;
						transform: scale(1.5, 1);
					}
				}

				.md-checkbox {
					display: inline;

					input[type='checkbox'] {
						height: 0;
						pointer-events: none;
					}
					> label {
						margin: 0;
						font-size: 10.5px;
						vertical-align: middle;
						display: inline-block;

						> span {
							font-size: 13.5px;
						}
					}
				}
			}

		}
	}

	&:hover:not(.active):not(.disabled) {
		box-shadow: 0 1px 3px var(--bg-secondary);
		> .app-dropdown-box:not(.t-bg-lf-color) {
			background: var(--light-blue) !important;
		}
		> .app-dropdown-box.t-bg-lf-color {
			&:after {
				opacity: 0.15;
			}
		}
	}

	&.active {
		overflow: visible;

		.app-dropdown-menu-container {
			visibility: visible;
			opacity: 1;
		}
		.app-dropdown-menu-container {
			z-index: 1050;
		}

		.app-dropdown-box {
			> .fa-chevron-down {
				transform: rotate(90deg);
			}
		}
	}

	&.disabled {
		cursor: not-allowed;

		.app-dropdown-menu-container {
			visibility: hidden !important;
			opacity: 0 !important;
		}

		.app-dropdown-box {
			pointer-events: none;
			opacity: 0.5;
			&:not(.t-bg-primary) {
				background: var(--gray);
				opacity: 1;
			}
		}
	}

	&.only-one-item {
		cursor: default;
	}
}

.md-tabs {
	position: relative;
	display: flex;
	border-bottom: solid 1px var(--gray2);

	> *:not(.slide) {
		transition: all 0.2s;
		background: transparent;

		> input[type="radio"][name="tabs"] {
			position: absolute;
			z-index: -1;
			opacity: 0;
			&:checked + label {
				color: var(--bs-primary) !important;
				font-weight: bold;
				pointer-events: none;
			}
		}

		> label {
			cursor: pointer;
			color: var(--gray-dark) !important;
			margin: 0;
			padding: 15px 7px;
			box-sizing: border-box;
			width: var(--md-tabs-width);
			display: inline-flex !important;
			align-items: center;
			justify-content: center;
			text-align: center;
			height: auto;
			transition: color 0.2s ease;
		}

		> label > .fa-ellipsis-v, .md-tabs-dropdown-menu {
			display: none;
		}

		&:not(.selected):hover {
			background: linear-gradient(hsla(var(--bs-primary-h), var(--bs-primary-s), 70%, 0.08), hsla(var(--bs-primary-h), var(--bs-primary-s), 80%, 0.03));
		}

		&.md-tabs-dropdown-tab {
			position: relative;

			> label > .fa-ellipsis-v , .md-tabs-dropdown-menu{
				display: block;
			}
			.md-tabs-dropdown-menu {
				visibility: hidden;
				opacity: 0;
				transition: all 0.07s;

				position: absolute;
				top: 100%;
				left: 0;
				transform: translateY(5px);
				background: var(--bg-primary) white;
				box-shadow: 1px 3px 2px var(--gray2);
				z-index: 1030;
				min-width: 100%;

				ul {
					width: 100%;
					margin: 0;
					padding: 0;
					list-style: none;

					&:after { // a layer to apply hover when on top of the tab slider, too
						content: "";
						position: absolute;
						top: -3px;
						height: 3px;
						left: 0;
						right: 0;
					}

					li {
						text-align: left;
						width: 100%;
						padding: 13px 15px;
						font-size: 13px;
						margin: 0;
						color: var(--color-primary);
						border-radius: 4px;
						transition: background .1s ease-out;
						cursor: pointer;

						.fa, .fas, .far, .zmdi {
							font-size: 13px;
							flex-basis: 15px;
							color: var(--gray2);
						}

						.fa-external-link-alt {
							position: absolute;
							right: 0;
							font-size: 9px;
							color: slategrey;
						}

						&:not(.static-menu-item):hover {
							background: rgba(204, 204, 204, 0.15) !important;
							color: var(--bs-primary);
						}

						&.expandable {
							position: relative;
							padding-right: 12px;

							.sub-menu {
								visibility: hidden;
								opacity: 0;
								left: -1px;
								right: unset;
								transform: translateX(-100%) translateY(0);
								box-shadow: -1px 1px 3px var(--gray);
								top: 0;
								transition: all .1s ease-out;

								&:after, &:before {
									content: none;
								}
							}

							&:hover {
								.sub-menu {
									visibility: visible;
									opacity: 1;
								}
							}

							&:after {
								content: "▾";
								position: absolute;
								right: 10px;
								font-size: 15px;
								transform: scale(1.5, 1);
							}
						}
					}

					li.static-menu-item {
						background: var(--bg-secondary);
						padding: 15px;
						cursor: default;
					}
				}
			}
			&:hover {
				.md-tabs-dropdown-menu {
					visibility: visible;
					opacity: 1;
					transform: translateY(0);
				}
			}
		}
	}

	> .slide {
		background:  var(--bs-bold-color);
		width: var(--md-tabs-width);
		height: 3px;
		position: absolute;
		top: calc(100% - 3px);
		transition: left 0.3s ease-out;
	}

}

app-tracking, app-postmark, app-advanced {
	display: contents;
}

.trustifi-loader {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 150px; /* container width*/
	height: 150px; /* container height*/
	max-height: 150px;
	max-width: 150px;

	.trustifi-loader-animation {
		display: block;
		width: 80%;
		height: 80%;
		border-radius: 50%;
		border-width: 3px;
		border: solid;
		border-color: var(--bs-primary) transparent var(--bs-primary) transparent;
		animation: trustifi-loader-animation 1.2s linear infinite;
	}

	.trustifi-logo-loader {
		width: 40%;

		path {
			fill: var(--bs-primary);
		}
	}

	.center {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}
@keyframes trustifi-loader-animation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}


.thin-scrollbar {
	&::-webkit-scrollbar {
		width: 7px;
	}
}

.thinner-scrollbar {
	&::-webkit-scrollbar {
		width: 5px;
	}
}
